import React from "react"

import Banner from "../../blocks/banner"
import InfoBlock from "../../blocks/info-cards"
import CallToActionBlockFirst from "../../blocks/call-to-action-block-first"
import InfoImg from "../../blocks/infoImg/InfoImg"
import infoImg1 from "../../assets/images/mobile/info1.svg"
import infoImg2 from "../../assets/images/mobile/info2.svg"
import actionImg from "../../assets/images/mobile/action.svg"
import { Imgs } from "../../@types/common"
import card1Img from "../../assets/images/mobile/card1.svg"
import card2Img from "../../assets/images/mobile/card2.svg"
import card3Img from "../../assets/images/mobile/card3.svg"
import card4Img from "../../assets/images/mobile/card4.svg"
import card5Img from "../../assets/images/mobile/card5.svg"
import card6Img from "../../assets/images/mobile/card6.svg"
import bannerImg1 from "../../assets/images/mobile/banner1.svg"
import bannerImg2 from "../../assets/images/mobile/banner2.svg"

const bannerImages: Imgs = {
  bannerImg1,
  bannerImg2,
}

const cardImages: Imgs = {
  card1Img: card1Img,
  card2Img: card2Img,
  card3Img: card3Img,
  card4Img: card4Img,
  card5Img: card5Img,
  card6Img: card6Img,
}

const infoImages: Imgs = {
  infoImg1: infoImg1,
  infoImg2: infoImg2,
}

const actionImages: Imgs = {
  actionImg: actionImg,
}

const sections = [
  { component: (key: number, className: string, data: any) => <Banner data={data} className={className} key={key} imgs={bannerImages} /> },
  { component: (key: number, className: string, data: any) => <InfoBlock data={data} className={className} key={key} images={cardImages} /> },
  { component: (key: number, className: string, data: any) => <InfoImg data={data.data.content.dataset.Section3} className={className} key={key} images={infoImages} /> },
  { component: (key: number, className: string, data: any) => <CallToActionBlockFirst data={data} className={className} key={key} images={actionImages} /> },
]

const MobileSections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-mobile_${i}`, data))}</>
}

MobileSections.displayName = "MobileSections"

export default MobileSections
