import React from "react"
import SEO from "../components/SEO/seo"
import Layout from "../layout/layout"
import { graphql } from "gatsby"
import MobileSections from "../pagesStructures/mobile/mobileSections"

const Mobile = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiMobileAppPage.content.find((el: any) => {
    return el.language === pageContext.lang
  })

  return (
    <Layout bgCustomClass="chatKit">
      <SEO title="Mobile" />
      <MobileSections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default Mobile

export const query = graphql`
  query MobilePage($url: String) {
    strapiMobileAppPage(url: { eq: $url }) {
      content {
        content {
          dataset {
            Section3 {
              heading
              imgAlt
              imgSrc
              paragraphs
            }
            bannerBlock {
              buttons {
                btnLink
                btnText
              }
              images {
                imgAlt
                imgSrc
              }
              text
              title
            }
            callToActionBlockFirst {
              buttons {
                btnLink
                btnText
                foreignLink
              }
              heading
              paragraph
              title
              imgSrc
              imgAlt
            }
            infoBlock {
              cards {
                buttonHref
                buttonText
                imgSrc
                text
                title
              }
              heading
            }
          }
        }
        language
      }
    }
  }
`
